import { buildUrl, checkIsVideoCloudId } from "@/lib/useCloudinary";
import { Picture, Link, Video } from "@/ui";

import useStyles from "./styles";

const Cover = ({ alt, link, as, mobile, desktop, src }) => {
  const classes = useStyles();
  const isVideo = checkIsVideoCloudId(src || desktop.cloudId);

  const handleLinkClick = (e) => {
    if (isVideo) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const renderMedia = () => (
    <>
      {!isVideo && (
        <Picture
          alt={alt}
          src={src}
          loading="eager"
          fetchPriority="high"
          className={`${classes.picture} ${
            desktop?.cloudId ? "" : classes.noImageSet
          }`}
          sizes={{
            md: {
              // src: desktop?.photoUrl,
              cloudId: desktop?.cloudId,
              transformations: { effect: desktop?.photoFilter },
              width: 1192,
              height: 670,
            },
            sm: {
              // src: desktop?.photoUrl,
              cloudId: desktop?.cloudId,
              transformations: { effect: desktop?.photoFilter },
              width: 960,
              height: 540,
            },
            xs: {
              // src: mobile?.photoUrl || desktop?.photoUrl,
              cloudId: mobile?.cloudId || desktop?.cloudId,
              transformations: {
                effect: mobile?.photoFilter || desktop?.photoFilter,
              },
              width: 480,
              height: 640,
            },
          }}
        />
      )}

      {isVideo && (
        <Video
          controls={false}
          cloudId={desktop?.cloudId}
          poster={buildUrl(desktop?.cloudId, {
            format: "jpg",
            startOffset: 1.5,
          })}
          classes={{
            root: `${classes.picture} ${
              desktop?.cloudId ? "" : classes.noImageSet
            }`,
            playButton: classes.playButton,
          }}
          transformations={{
            quality: "auto:best",
            crop: "scale",
            width: 1280,
            height: 720,
          }}
        />
      )}
    </>
  );

  return link ? (
    <Link href={link} as={as || link} onClick={handleLinkClick}>
      {renderMedia()}
    </Link>
  ) : (
    <>{renderMedia()}</>
  );
};

export default Cover;
